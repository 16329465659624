<template>
  <div class="dlist__datepicker">
      <a href="#" @click.prevent="goBack()">&lt; </a>
      <span v-if="focusDate">{{focusDate.format(translations['date_format'])}}</span>

      <a href="#" @click.prevent="goForward()">&gt; </a>
  </div>
</template>

<script>
export default {
  props: ['uuid', 'selectedProduct', 'timezone', 'focusDate', "translations"],
  methods: {
    goBack(){
      this.$emit('pickDate', this.focusDate.clone().add(-1, 'days'))
    },
    goForward(){
      this.$emit('pickDate', this.focusDate.clone().add(+1, 'days'))
    }
  }
}
</script>
