<template>
  <div class="dlist-widget__content">
    <template v-if="mode == 'day'">
      <div class="dlist-widget__header">
        <div class="dlist-widget__header__image">
          <img :src="imageUrl" v-if="imageUrl" />
        </div>
        <div class="dlist-widget__header__titles">
          <h1>{{ translations["calendar_title"] }}</h1>
          <h2>{{ translations["calendar_subtitle"] }}</h2>
        </div>
      </div>
      <DayPicker
        :focus-date="focusDate"
        :uuid="uuid"
        :selectedProduct="selectedProduct"
        :timezone="timezone"
        :translations="translations"
        @pickDate="focusDate = $event.clone()"
      ></DayPicker>
      <DayView
        :selected-date="selectedDate"
        :focus-date="focusDate"
        :uuid="uuid"
        :show-duration="showDuration"
        :selectedProduct="selectedProduct"
        :timezone="timezone"
        :translations="translations"
        @pickDate="
          selectedDate = $event.clone();
          focusDate = $event.clone();
            $emit('selectDate', $event.clone());
        "
      ></DayView>
    </template>

    <template v-if="mode == 'month'">
      <div class="dlist-widget__header">
        <div class="dlist-widget__header__image">
          <img :src="imageUrl" v-if="imageUrl" />
        </div>
        <div class="dlist-widget__header__titles">
          <h1>{{ translations["calendar_title"] }}</h1>
          <h2>{{ translations["calendar_subtitle"] }}</h2>
        </div>
      </div>
      <template v-if="step != 'hour'">
        <MonthPicker
          :focus-date="focusDate"
          :uuid="uuid"
          :selectedProduct="selectedProduct"
          :timezone="timezone"
          :translations="translations"
          @pickDate="focusDate = $event.clone()"
        ></MonthPicker>
        <MonthView
          :selected-date="selectedDate"
          :focus-date="focusDate"
          :uuid="uuid"
          :show-duration="showDuration"
          :selectedProduct="selectedProduct"
          :timezone="timezone"
          :translations="translations"
          @pickDate="
            selectedDate = $event.clone();
            focusDate = $event.clone();
            step = 'hour';
          "
        ></MonthView>
      </template>

      <template v-else>
        <DayPicker
          :focus-date="focusDate"
          :uuid="uuid"
          :selectedProduct="selectedProduct"
          :timezone="timezone"
          :translations="translations"
          @pickDate="focusDate = $event.clone()"
        ></DayPicker>
        <DayView
          :selected-date="selectedDate"
          :focus-date="focusDate"
          :uuid="uuid"
          :show-duration="showDuration"
          :selectedProduct="selectedProduct"
          :timezone="timezone"
          :translations="translations"
          @pickDate="
            selectedDate = $event.clone();
            focusDate = $event.clone();
            $emit('selectDate', $event.clone());
          "
        ></DayView>
      </template>
    </template>

    <template v-if="mode == 'week'">
      <div class="dlist-widget__header">
        <div class="dlist-widget__header__image">
          <img :src="imageUrl" v-if="imageUrl" />
        </div>
        <div class="dlist-widget__header__titles">
          <h1>{{ translations["calendar_title"] }}</h1>
          <h2>{{ translations["calendar_subtitle"] }}</h2>
        </div>
      </div>
      <WeekPicker
          v-if="focusDate"
        :focus-date="focusDate"
        :uuid="uuid"
        :selectedProduct="selectedProduct"
        :translations="translations"
        :timezone="timezone"
        @pickDate="focusDate = $event.clone()"
      ></WeekPicker>
      <WeekView
          v-if="focusDate"
        :selected-date="selectedDate"
        :focus-date="focusDate"
        :uuid="uuid"
        :show-duration="showDuration"
        :selectedProduct="selectedProduct"
        :translations="translations"
        :timezone="timezone"
        @pickDate="
          selectedDate = $event.clone();
          focusDate = $event.clone();
            $emit('selectDate', $event.clone());
        "
      ></WeekView>
    </template>
    <template v-if="mode == 'standalone' || mode == 'hybrid'">
      <div class="dlist-widget__content__col1">
        <MonthPicker
            v-if="focusDate"
          :focus-date="focusDate"
          :uuid="uuid"
          :selectedProduct="selectedProduct"
          :translations="translations"
          :timezone="timezone"
          @pickDate="focusDate = $event.clone()"
        ></MonthPicker>
        <MonthView
            v-if="focusDate"
          :selected-date="selectedDate"
          :focus-date="focusDate"
          :uuid="uuid"
          :show-duration="showDuration"
          :selectedProduct="selectedProduct"
          :translations="translations"
          :timezone="timezone"
          @pickDate="
            selectedDate = $event.clone();
            focusDate = $event.clone();
          "
        ></MonthView>
      </div>
      <div class="dlist-widget__content__col2">
        <div class="dlist-widget__header">
          <div class="dlist-widget__header__image">
            <img :src="imageUrl" v-if="imageUrl" />
          </div>
          <div class="dlist-widget__header__titles">
            <h1>{{ translations["calendar_title"] }}</h1>
            <h2>{{ translations["calendar_subtitle"] }}</h2>
          </div>
        </div>
        <DayView
            v-if="focusDate"
          :selected-date="selectedDate"
          :focus-date="selectedDate"
          :uuid="uuid"
          :show-duration="showDuration"
          :selectedProduct="selectedProduct"
          :translations="translations"
          :timezone="timezone"
          @pickDate="
            focusDate = $event.clone();
            $emit('selectDate', $event.clone());
          "
        ></DayView>
      </div>
    </template>
  </div>
</template>

<script type="text/javascript">
import WeekPicker from "@/components/PickerComponents/WeekPicker";
import DayPicker from "@/components/PickerComponents/DayPicker";
import MonthPicker from "@/components/PickerComponents/MonthPicker";
import DayView from "@/components/PickerComponents/DayView";
import MonthView from "@/components/PickerComponents/MonthView";
import WeekView from "@/components/PickerComponents/WeekView";
import moment from "moment";
import axios from "axios";

export default {
  components: {
    WeekPicker,
    DayPicker,
    MonthPicker,
    DayView,
    MonthView,
    WeekView,
  },
  props: [
    "mode",
    "defaultFocus",
    "uuid",
    "imageUrl",
    "timezone",
    "showDuration",
    "translations",
    "selectedProduct",
  ],
  data: function(){
    return {
      selectedDate: null,
      focusDate: null
    }
  },
  mounted() {
    if(this.defaultFocus == 'today'){
      this.selectedDate = moment();
      this.focusDate = moment();
    }
    else{
      let from = moment().toISOString(true);
      let to = moment().add(90, 'day').toISOString(true);
      axios
          .get(
              `${window.dlistHost}/calapi/v1/availability?uuid=${this.uuid}&product_id=${this.selectedProduct.id}&from=${from}&to=${to}&limit=1`
          )
          .then((response) => {
            let slot = response.data[0];
            if(slot){
              this.selectedDate = moment.parseZone(slot[0])
              this.focusDate = moment.parseZone(slot[0])
            }
            else{
              this.selectedDate = moment();
              this.focusDate = moment();
            }
          });
    }
  }
};
</script>
