<template>
    <div class="dlist__table-wrapper">
    <div class="dlist__loader" v-if="loading">
      <Loader />
    </div>
    <div style="display: flex;">
        <div v-for="(dayinfo, index) in week" :key="index" style=" width: 100%">

            <table style="width: 100%">
                <thead>
                <tr>
                    <th>{{dayinfo[0].format(translations['day_format'])}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(slot, id) in dayinfo[1]" :key="id"
                    @click.prevent="$emit('pickDate', slot[0])">
                    <td :class="slot[2] < 0 ? 'disabled': ''">
                        {{slot[0].clone().parseZone().format('H:mm')}}
                        <template v-if="showDuration">
                          -
                          {{ slot[0].clone().parseZone().add(selectedProduct.duration, 'minutes').format(hoursFormat) }}
                        </template>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    </div>
</template>

<style scoped>
</style>

<script>
    import moment from 'moment';
    import axios from 'axios';
    import Loader from "@/components/Loader";

    export default {
      components: {Loader},
      data() {
            return {
              loading: false,
              slots: []
            }
        },
        computed: {
          hoursFormat(){
            return this.translations['hours_format'] == 1 ? 'HH:mm' : "hh:mm A"
          },
            week() {
                let result = [];

                if(!this.focusDate) return;

              let weekKind = this.translations.start_monday == 1 ? "isoWeek" : "week";
              let startDate = this.focusDate.clone().startOf(weekKind);
                let endDate = this.focusDate.clone().endOf(weekKind);
                let date = startDate.clone();

                while(!date.isAfter(endDate)){
                    result.push([date.clone(), this.slots.filter((slot) => slot[0].clone().utc(true).isSame(date.clone().utc(true), 'date'))]);
                    date.add(1, 'days');
                }

                return result;
            }
        },
        props: ['uuid', 'selectedProduct', 'timezone', 'selectedDate', 'focusDate', "translations", "showDuration"],
        watch: {
          focusDate: function () {
                this.fetchData()
            }
        },
        mounted(){
            this.fetchData()
        },

        methods: {
          fetchData: function () {
            let pthis = this;
            this.slots = [];
            if (this.focusDate == null) {
              return;
            }
            let weekKind = this.translations.start_monday == 1 ? "isoWeek" : "week";
            let from = this.focusDate.clone().startOf(weekKind);
            let to = this.focusDate.clone().endOf(weekKind);

            pthis.loading = true;
            axios
                .get(`${window.dlistHost}/calapi/v1/availability?uuid=${this.uuid}&product_id=${this.selectedProduct.id}&from=${from}&to=${to}`)
                .then(function (response) {
                  pthis.loading = false;
                  pthis.slots = response.data.map(slot => {
                    return [moment.parseZone(slot[0]), moment.parseZone(slot[1]), slot[2]];
                  });
                });
          }
        }
    }
</script>
