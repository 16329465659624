<template>
  <div :class="'dlist-widget ' + 'dlist-widget--' + mode">
    <!-- The loading order is important
    1. Inline minimum style to display a proper loader
    2. Main loader (loading = true)
    3. Then, load external style
    4. Main loader (loading = false)
    4. Then, product list (loading = true)
    5. Then, product list (loading = false)

    It is important to respect style order.
    Inlines styles are just enought for the main loader.
    Then, we need background, spacing, border, ... to show product loader
    !-->

    <component :is="'style'" type="text/css">
      .dlist-widget { position: relative; min-height: 100px; }

      /* Loader */

      .dlist__loader {
        position: absolute;
        min-height: 100px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }

      .sk-circle {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .sk-circle .sk-child {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .sk-circle .sk-child:before {
        content: "";
        display: block;
        margin: 0 auto;
        width: 15%;
        height: 15%;
        background-color: #333333;
        border-radius: 100%;
        -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
        animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      }
      .sk-circle .sk-circle2 {
        -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
        transform: rotate(30deg);
      }
      .sk-circle .sk-circle3 {
        -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        transform: rotate(60deg);
      }
      .sk-circle .sk-circle4 {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      .sk-circle .sk-circle5 {
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg);
      }
      .sk-circle .sk-circle6 {
        -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
        transform: rotate(150deg);
      }
      .sk-circle .sk-circle7 {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
      .sk-circle .sk-circle8 {
        -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
        transform: rotate(210deg);
      }
      .sk-circle .sk-circle9 {
        -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
        transform: rotate(240deg);
      }
      .sk-circle .sk-circle10 {
        -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
      }
      .sk-circle .sk-circle11 {
        -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
        transform: rotate(300deg);
      }
      .sk-circle .sk-circle12 {
        -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
        transform: rotate(330deg);
      }
      .sk-circle .sk-circle2:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
      .sk-circle .sk-circle3:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
      .sk-circle .sk-circle4:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
      .sk-circle .sk-circle5:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
      .sk-circle .sk-circle6:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }
      .sk-circle .sk-circle7:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }
      .sk-circle .sk-circle8:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
      .sk-circle .sk-circle9:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
      .sk-circle .sk-circle10:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
      .sk-circle .sk-circle11:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }
      .sk-circle .sk-circle12:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }

      @-webkit-keyframes sk-circleBounceDelay {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      @keyframes sk-circleBounceDelay {
        0%,
        80%,
        100% {
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        40% {
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    </component>

    <div class="dlist__loader" v-if="loadingStyle">
      <Loader />
    </div>

    <link rel="stylesheet" :href="'https://datelist.io/calapi/v1/stylesheet/' + uuid" v-on:load="loadingStyle=false" v-on:error="loadingStyle=false">

    <template v-if="!loadingStyle">
      <!-- Show products, ... only when the style is loaded -->
      <template v-if="disabled">
        <div class="dlist-widget--disabled">
          <h1>{{ translations["widget_disabled"] }}</h1>
        </div>
      </template>
      <template v-else>
        <template v-if="step == 'product'">
          <ProductSelector
              :loading="loadingInfo"
              :products="products"
              :currency-code="currencyCode"
              :image-url="imageURL"
              :translations="translations"
              :require-payment="requirePayment"
              @select="
            selectedProduct = $event;
            step = 'home';
          "
          />
        </template>
        <template v-else-if="step == 'home'">
          <TimePicker
              :default-focus="defaultFocus"
              :selected-product="selectedProduct"
              :image-url="imageURL"
              :show-duration="showDuration"
              :translations="translations"
              :uuid="uuid"
              :mode="mode"
              :timezone="timezone"
              @selectDate="
            step = requirePayment === 'optional' && selectedProduct.price > 0 ? 'choose-payment' : 'form';
            validationsRan = false;
            selectedDate = $event;
          "
          />
        </template>
        <template v-else-if="step == 'choose-payment'">
          <div class="dlist-widget__choosepayment">
            <p>
              {{ translations["payment_request"] }}
            </p>
            <div>
              <input
                  type="button"
                  :value="translations['payment_online']"
                  @click.prevent="payonline = true; step = 'form'"
              />
            </div>
            <div>
              <input
                  type="button"
                  :value="translations['payment_onsite']"
                  @click.prevent="payonline = false; step = 'form'"
              />
            </div>
          </div>
        </template>
        <template v-else-if="step == 'form'">
          <div class="dlist-widget__form">
            <p>
              {{ translations["you_picked_for"] }}
              {{ selectedDate.format(translations["date_format"]) }}
              {{ translations["at"] }}
              {{translations["hours_format"] == 1 ? selectedDate.format('HH:mm') : selectedDate.format('hh:mma')}}
            </p>
            <p>
              {{ translations["confirm_details"] }}
            </p>
            <form @submit.prevent="submitForm()">
              <input
                  type="hidden"
                  name="selected_date"
                  :value="selectedDate.toDate().toISOString(true)"
              />
              <FormInput
                  classes="dlist-widget__form__field__email"
                  field-id="email"
                  translation-key="field_email"
                  :value="inputEmail"
                  required="true"
                  @input="inputEmail = $event"
                  type="email"
                  autocomplete="email"
                  :translations="translations"
                  :validationsRan="validationsRan"
              />
              <template v-if="showPhone">
                <FormInput
                    classes="dlist-widget__form__field__phone"
                    field-id="phone"
                    translation-key="field_phone"
                    :value="inputPhone"
                    :required="requirePhone"
                    @input="inputPhone = $event"
                    :type="hasLegacyPhone ? 'legacyphone' : 'phone'"
                    autocomplete="tel"
                    :validationsRan="validationsRan"
                    :translations="translations" />
              </template>
              <template v-if="showName">
                <FormInput
                    classes="dlist-widget__form__field__firstname"
                    field-id="firstname"
                    type="text"
                    translation-key="field_first_name"
                    autocomplete="given-name"
                    :translations="translations"
                    :required="requireName"
                    :value="inputFirstname"
                    :validationsRan="validationsRan"
                    @input="inputFirstname = $event"
                />
                <FormInput
                    classes="dlist-widget__form__field__lastname"
                    field-id="lastname"
                    type="text"
                    translation-key="field_last_name"
                    autocomplete="family-name"
                    :translations="translations"
                    :required="requireName"
                    :value="inputLastname"
                    :validationsRan="validationsRan"
                    @input="inputLastname = $event"
                />
              </template>
              <template
                  v-for="[id, definition] in customFields">
                <FormInput
                    classes="dlist-widget__form__field__custom"
                    :field-id="'custom_' + id"
                    :type="definition.type"
                    :translation-key="'custom_fields_' + id"
                    :required="definition.required"
                    :choices="definition.choices"
                    :translations="translations"
                    :value="customFieldValues[id]"
                    :validationsRan="validationsRan"
                    :key="id"
                    @input="customFieldValues[id] = $event; customFieldValues = {...customFieldValues}"
                    v-if="definition.visible"
                />
              </template>
              <div
                  class="dlist-widget__form__field dlist-widget__form__field__actions"
              >
                <input
                    type="submit"
                    :value="translations['confirm']"
                    :class="'dlist-widget__form__submit ' + (submitting ? 'dlist-widget__form__submit--submitting' : '')"
                    :disabled="validationsRan && formDisabled"
                />
              </div>
              <div class="dlist-widget__form__field">
                <input
                    type="button"
                    :value="translations['back']"
                    @click.prevent="step = 'home'"
                />
              </div>
            </form>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import '@/assets/main.css';
import axios from "axios";
import FormInput from "@/components/FormInput";
import ProductSelector from "@/components/ProductSelector";
import TimePicker from "@/components/TimePicker";
import Loader from "@/components/Loader";

export default {
  props: ["uuid", "forcedMode", "forcedLanguage", "forcedProductId", "forcedDuration", "defaultValues"],
  data() {
    let defaultValues = this.defaultValues || {};
    let defaultCustomValues = {}
    for(let key in this.defaultValues || {}){
      if(parseInt(key).toString() == key){
        defaultCustomValues[key] = this.defaultValues[key];
      }
    }

    return {
      defaultFocus: null,
      selectedDate: null,
      selectedProduct: null,
      widgetVersion: null,
      step: "product",

      loadingStyle: true,
      loadingInfo: true,

      validationsRan: false,
      submitting: false,

      products: [],

      showDuration: false,
      mode: '',

      payonline: false,
      disabled: false,
      name: "",
      hasLegacyPhone: false,
      requirePayment: false,
      paymentGateway: '',
      currencyCode: '',
      phoneMode: "",
      imageURL: "",
      requirePhone: "",
      requireName: "",
      showPhone: "",
      showName: "",
      timezone: "",
      customFields: [],
      customFieldValues: defaultCustomValues,

      translations: {},
      inputEmail: defaultValues.email || '',
      inputFirstname: defaultValues.first_name || '',
      inputLastname: defaultValues.last_name || '',
      inputPhone: defaultValues.phone || '',
      inputPlaces: 1,
    };
  },
  components: {
    TimePicker,
    ProductSelector,
    FormInput,
    Loader,
  },

  computed: {
    formDisabled() {
      for(let i = 0 ; i < this.customFields.length ; i++){
        let [id, definition] = this.customFields[i];
        if(definition.required && !this.customFieldValues[id]) return true;
      }

      return (
        !this.inputEmail ||
        (this.requirePhone && !this.inputPhone) ||
        (this.requireName && (!this.inputFirstname || !this.inputLastname))
      );
    },
  },

  methods: {
    submitForm() {
      this.validationsRan = true;
      if (this.formDisabled) return;

      if(this.submitting) return;
      this.submitting = true; // Disable double submit

      let pthis = this;
      axios
        .post(`${window.dlistHost}/calapi/v1/book?uuid=${this.uuid}`, {
          payonline: this.payonline,
          language: this.language,
          email: this.inputEmail,
          phone: this.inputPhone,
          first_name: this.inputFirstname,
          last_name: this.inputLastname,
          product_id: this.selectedProduct.id,
          selected: this.selectedDate,
          custom_field_values: this.customFieldValues,
        })
        .then(function (response) {
          pthis.submitting = false;
          if (response.data.redirect) {
            window.location = response.data.redirect;
          } else if (response.data.status == "payment_required") {
            // eslint-disable-next-line no-debugger
            if(pthis.paymentGateway == 'stripe'){
              const stripe = window.Stripe(response.data.key);
              stripe.redirectToCheckout({ sessionId: response.data.sessionId });
            }
            else if(pthis.paymentGateway == 'paypal'){
              window.location = response.data.sessionId;
            }
          } else {
            alert("error");
          }
        });
    },
  },
  mounted() {
    let pthis = this;
    pthis.loadingStyle = true;
    pthis.loadingInfo = true;

    let widgetVersion = require("../../package.json").version;
    this.widgetVersion = widgetVersion;

    let windowLocation = window.location.href;

    axios
      .get(
        `${window.dlistHost}/calapi/v1/info?uuid=${
          this.uuid
        }&version=${widgetVersion}&location=${encodeURI(windowLocation)}`
      )
      .then(function (response) {

        pthis.showDuration = pthis.forcedDuration || response.data.design_show_duration;
        pthis.mode = pthis.forcedMode || response.data.design_format;
        pthis.language = pthis.forcedLanguage || 'default';
        pthis.defaultProductId = pthis.forcedProductId;

        pthis.translations = response.data.translations[pthis.language] || {};
        pthis.name = response.data.name;

        pthis.defaultFocus = response.data.default_focus;
        pthis.requirePayment = response.data.require_payment;
        pthis.paymentGateway = response.data.payment_gateway;
        pthis.currencyCode = response.data.currency_code;
        pthis.requirePhone = response.data.require_phone;
        pthis.requireName = response.data.require_name;
        pthis.showPhone = response.data.show_phone;
        pthis.showName = response.data.show_name;
        pthis.imageURL = response.data.image_url;
        pthis.hasLegacyPhone = response.data.legacy_phone;
        pthis.customFields = Object.entries(response.data.custom_fields).sort((a, b) => {
          let [idA] = a;
          let [idB] = b;

          let order = response.data.custom_fields_order.map((x) => x.toString());
          return order.indexOf(idA.toString()) - order.indexOf(idB.toString());
        });
        pthis.timezone = response.data.timezone;
        pthis.products = response.data.products;
        pthis.disabled = response.data.disabled;

        if (pthis.defaultProductId) {
          pthis.selectedProduct = pthis.products.find(
            (product) => product.id == pthis.defaultProductId
          );
          pthis.step = "home";
        }

        // Add stripe.js if requiring payment
        if (pthis.requirePayment != 'no' && pthis.paymentGateway == 'stripe') {
          let script = document.createElement("script");
          script.setAttribute("src", "https://js.stripe.com/v3/");
          document.head.appendChild(script);
        }

        pthis.loadingInfo = false;
      });
  },
};
</script>
