<template>
  <div class="dlist__table-wrapper">
    <div class="dlist__loader" v-if="loading">
      <Loader />
    </div>
    <table style="width: 100%">
      <tr v-for="(slot, id) in day" :key="id">
        <td
          :class="slot[2] < 0 ? 'disabled' : ''"
          @click.prevent="$emit('pickDate', slot[0])"
        >
          {{ slot[0].clone().parseZone().format(hoursFormat) }}
          <template v-if="showDuration">
            -
            {{ slot[0].clone().parseZone().add(selectedProduct.duration, 'minutes').format(hoursFormat) }}
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  data() {
    return {
      loading: false,
      slots: [],
    };
  },
  computed: {
    hoursFormat(){
      return this.translations['hours_format'] == 1 ? 'HH:mm' : "hh:mm A"
    },
    day() {
      let result = [];

      result = this.slots.filter((slot) =>
        slot[0]
          .clone()
          .utc(true)
          .isSame(this.focusDate.clone().utc(true), "date")
      );

      return result;
    },
  },
  props: [
    "uuid",
    "selectedProduct",
    "timezone",
    "selectedDate",
    "focusDate",
    "translations",
    "showDuration"
  ],
  watch: {
    focusDate: function () {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      let pthis = this;
      this.slots = [];
      if (this.focusDate == null) {
        return;
      }

      let from = this.focusDate.clone().add(-1, "days").toISOString(true);
      let to = this.focusDate
        .clone()
        .add(+2, "days")
        .toISOString(true);

      pthis.loading = true;
      axios
        .get(
          `${window.dlistHost}/calapi/v1/availability?uuid=${this.uuid}&product_id=${this.selectedProduct.id}&from=${from}&to=${to}`
        )
        .then(function (response) {
          pthis.loading = false;
          pthis.slots = response.data.map((slot) => {
            return [
              moment.parseZone(slot[0]),
              moment.parseZone(slot[1]),
              slot[2],
            ];
          });
        });
    },
  },
};
</script>
