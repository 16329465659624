<template>
  <div
    :class="
      'dlist-widget__form__field dlist-widget__form__field--' +
      type +
      ' ' +
      classes +
      (invalid ? ' dlist-widget__form__field--invalid' : '')
    "
  >
    <label :for="fieldId"
      >{{ translations[translationKey]
      }}<template v-if="required"> ({{ translations["required"] }})</template
      >:</label
    >

    <template
      v-if="
        type == 'phone'
      "
    >

      <vue-tel-input
          :id="fieldId"
          placeholder=""
          :inputOptions="{placeholder: ''}"
          @open="onDropdownOpen(true)"
          @close="onDropdownOpen(false)"
          v-on:input="this.telInput">
        <template v-slot:arrow-icon>
          <span style="font-size: 8px !important; margin-left: 8px">{{ open ? '▲' : '▼' }}</span>
        </template>
      </vue-tel-input>
    </template>
    <template
      v-else-if="
        type == 'text' || type == 'email' || type == 'number'
      "
    >
      <input
        :id="fieldId"
        :type="type"
        placeholder=""
        :autocomplete="autocomplete"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
    </template>
    <template
      v-else-if="
        type == 'legacyphone'
      "
    >
      <input
        :id="fieldId"
        type="phone"
        placeholder=""
        :autocomplete="autocomplete"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
    </template>
    <template v-else-if="type == 'checkbox'">
      <input
        :id="fieldId"
        :type="type"
        placeholder=""
        :autocomplete="autocomplete"
        :value="value"
        @change="$emit('input', $event.target.checked)"
      />
    </template>
    <template v-else-if="type == 'textarea'">
      <textarea
        :id="fieldId"
        :type="type"
        placeholder=""
        :autocomplete="autocomplete"
        :value="value"
        @input="$emit('input', $event.target.value)"
      ></textarea>
    </template>
    <template v-else-if="type == 'radio'">
      <div
        v-for="(choice, id) in choices"
        :key="choice"
        class="dlist-widget__form__radio"
      >
        <input
          :id="fieldId + '_' + id"
          :name="fieldId"
          type="radio"
          placeholder=""
          :autocomplete="autocomplete"
          :value="choice"
          @change="$emit('input', $event.target.value)"
        />
        <label :for="fieldId + '_' + id">{{ choice }}</label>
      </div>
    </template>
    <template v-else-if="type == 'select'">
      <select
        :id="fieldId"
        placeholder=""
        :autocomplete="autocomplete"
        :value="value"
        @input="$emit('input', $event.target.value)"
      >
        <option v-for="option in choices" :key="option" v-bind:value="option">
          {{ option }}
        </option>
      </select>
    </template>
    <template v-else-if="type == 'file_single' || type == 'file_multiple'">
      <input
        :id="fieldId"
        placeholder=""
        type="file"
        multiple="type == 'file_multiple'"
        :autocomplete="autocomplete"
        @input="getFieldValue(type, $event.target).then((v) => $emit('input', v))"
      />
    </template>
    <template v-else> Cannot show field of type {{ type }} </template>
  </div>
</template>

<script type="text/javascript">
import { VueTelInput } from 'vue-tel-input';
export default {
  props: [
    "translations",
    "fieldId",
    "translationKey",
    "required",
    "choices",
    "value",
    "classes",
    "autocomplete",
    "type",
    "validationsRan",
  ],
  components: {
    VueTelInput,
  },

  computed: {
    isInvalid() {
      if (!this.validationsRan) return false;

      if (this.required && !this.value) return true;
      if (this.type == "email" && !this.value.includes("@")) return true;
      return false;
    },

    invalid() {
      return this.validationsRan && !this.value && this.required;
    },
  },

  data: function(){
    return {
      open: false,
    }
  },

  methods: {
    onDropdownOpen(value){
      this.open = value;
    },

    telInput(_input, phoneObject){
      this.$emit('input', phoneObject.number);
    },

    async getFieldValue(type, field) {
      const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });

      let data = []
      for (let file of field.files) {
        data.push([file.name, await toBase64(file)])
      }

      return data;
    }
  }
};
</script>
