<template>
    <div id="app">
        <Widget :uuid="uuid" forced-mode="standalone" forced-language="default"/>
        <hr />
        <Widget :uuid="uuid" forced-mode="day" forced-language="default"/>
        <hr />
        <Widget :uuid="uuid" forced-mode="week" forced-language="default"/>
        <hr />
        <Widget :uuid="uuid" forced-mode="month" forced-language="default"/>
    </div>
</template>

<script>
    import Widget from './components/Widget.vue'

    export default {
        components: {Widget},
        props: ['uuid', 'product', 'timezone', 'defaultProductId', 'showDuration']
    }
</script>

