<template>
  <div class="dlist__table-wrapper">
    <div class="dlist__loader" v-if="loading">
      <Loader />
    </div>
    <table style="width: 100%">
      <thead>
      <tr>
        <th v-for="(day, index) in week" :key="index">{{translations['day_' + day]}}</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(week, index) in month" :key="index">
          <td
                  v-for="(day, index2) in week"
                  :key="index2"
                  @click.prevent="$emit('pickDate', day[0])"
                  :class="
              (day[1].length <= 0 ? 'disabled' : '') +
                ' ' +
                (day[0].clone().utc(true).isSame(currentDay.clone().utc(true), 'date') ? 'today' : '') +
                ' ' +
                (day[0].clone().utc(true).isSame(selectedDate.clone().utc(true), 'date') ? 'selected' : '') +
                ' ' +
                (day[0].clone().utc(true).isSame(focusDate.clone().utc(true), 'month') ? '' : 'anothermonth')
            "
          >
            {{ day[0].format("DD") }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped></style>

<script>
import moment from "moment";
import axios from "axios";
import Loader from "@/components/Loader";

export default {
  components: {Loader},
  data() {
    let weekKind = this.translations.start_monday == 1 ? "isoWeek" : "week";

    let week = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    if(weekKind != "isoWeek")
      week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    return {
      loading: false,
      slots: [],
      week: week,
      currentDay: moment(new Date())
    };
  },
  computed: {
    month() {
      let result = [];

      if(!this.focusDate) return;

      let weekKind = this.translations.start_monday == 1 ? "isoWeek" : "week";

      let startDate = this.focusDate
        .clone()
        .startOf("month")
        .startOf(weekKind);

      let endDate = this.focusDate
        .clone()
        .endOf("month")
        .endOf(weekKind);

      let date = startDate.clone();

      // Filters slot by date
      while (!date.isAfter(endDate)) {
        result.push([
          date.clone(),
          this.slots.filter(slot => slot[0].clone().utc(true).isSame(date.clone().utc(true), "date")).filter(slot => slot[2] >= 0)
        ]);
        date.add(1, "days");
      }

      // Put in month view
      let groupedResult = [];
      for (let i = 0; i < result.length / 7; i++) {
        let buf = [];
        for (let j = 0; j < 7; j++) {
          buf.push(result[7 * i + j]);
        }
        groupedResult.push(buf);
      }

      return groupedResult;
    }
  },
  props: ['uuid', 'selectedProduct', 'timezone', "selectedDate", 'focusDate', "translations"],
  watch: {
    focusDate: function(newValue, oldValue) {
      // As on hybrid view, we can change the focus date without changing month, we shouldn't reload
      if(!moment(newValue).isSame(oldValue, 'month'))
        this.fetchData();
    }
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      let pthis = this;
      this.slots = [];

      let weekKind = this.translations.start_monday == 1 ? "isoWeek" : "week";

      let startDate = this.focusDate
          .clone()
          .startOf("month")
          .startOf(weekKind);

      let endDate = this.focusDate
          .clone()
          .endOf("month")
          .endOf(weekKind);

      pthis.loading = true;
      axios
        .get(`${window.dlistHost}/calapi/v1/availability?uuid=${this.uuid}&product_id=${this.selectedProduct.id}&from=${startDate}&to=${endDate}&groupby=day`)
        .then(function(response) {
          pthis.loading = false;
          pthis.slots = response.data.map(slot => {
            return [moment.parseZone(slot[0]), moment.parseZone(slot[1]), slot[2]];
          });
        });
    }
  }
};
</script>
