<template>
  <div class="dlist__datepicker">
      <a href="#" @click.prevent="goBack()">&lt; </a>
      <span v-if="focusDate">{{focusDate.startOf(weekKind).format(translations['date_format'])}}</span>

      <a href="#" @click.prevent="goForward()">&gt; </a>
  </div>
</template>

<script>
  export default {
    props: ['uuid', 'selectedProduct', 'timezone', 'focusDate', "translations"],
    methods: {
      goBack(){
        this.$emit('pickDate', this.focusDate.clone().add(-7, 'days'))
      },
      goForward(){
        this.$emit('pickDate', this.focusDate.clone().add(+7, 'days'))
      }
    },
    computed: {
      weekKind(){
        return this.translations.start_monday == 1 ? "isoWeek" : "week";
      }
    }
  }
</script>
