var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'dlist-widget__form__field dlist-widget__form__field--' +
    _vm.type +
    ' ' +
    _vm.classes +
    (_vm.invalid ? ' dlist-widget__form__field--invalid' : '')},[_c('label',{attrs:{"for":_vm.fieldId}},[_vm._v(_vm._s(_vm.translations[_vm.translationKey])),(_vm.required)?[_vm._v(" ("+_vm._s(_vm.translations["required"])+")")]:_vm._e(),_vm._v(":")],2),(
      _vm.type == 'phone'
    )?[_c('vue-tel-input',{attrs:{"id":_vm.fieldId,"placeholder":"","inputOptions":{placeholder: ''}},on:{"open":function($event){return _vm.onDropdownOpen(true)},"close":function($event){return _vm.onDropdownOpen(false)},"input":this.telInput},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('span',{staticStyle:{"font-size":"8px !important","margin-left":"8px"}},[_vm._v(_vm._s(_vm.open ? '▲' : '▼'))])]},proxy:true}],null,false,3314846218)})]:(
      _vm.type == 'text' || _vm.type == 'email' || _vm.type == 'number'
    )?[_c('input',{attrs:{"id":_vm.fieldId,"type":_vm.type,"placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})]:(
      _vm.type == 'legacyphone'
    )?[_c('input',{attrs:{"id":_vm.fieldId,"type":"phone","placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})]:(_vm.type == 'checkbox')?[_c('input',{attrs:{"id":_vm.fieldId,"type":_vm.type,"placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.checked)}}})]:(_vm.type == 'textarea')?[_c('textarea',{attrs:{"id":_vm.fieldId,"type":_vm.type,"placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}})]:(_vm.type == 'radio')?_vm._l((_vm.choices),function(choice,id){return _c('div',{key:choice,staticClass:"dlist-widget__form__radio"},[_c('input',{attrs:{"id":_vm.fieldId + '_' + id,"name":_vm.fieldId,"type":"radio","placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":choice},on:{"change":function($event){return _vm.$emit('input', $event.target.value)}}}),_c('label',{attrs:{"for":_vm.fieldId + '_' + id}},[_vm._v(_vm._s(choice))])])}):(_vm.type == 'select')?[_c('select',{attrs:{"id":_vm.fieldId,"placeholder":"","autocomplete":_vm.autocomplete},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}},_vm._l((_vm.choices),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0)]:(_vm.type == 'file_single' || _vm.type == 'file_multiple')?[_c('input',{attrs:{"id":_vm.fieldId,"placeholder":"","type":"file","multiple":"type == 'file_multiple'","autocomplete":_vm.autocomplete},on:{"input":function($event){_vm.getFieldValue(_vm.type, $event.target).then(function (v) { return _vm.$emit('input', v); })}}})]:[_vm._v(" Cannot show field of type "+_vm._s(_vm.type)+" ")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }