import Vue from 'vue'
import Widget from './components/Widget.vue'
import App from './App.vue'

import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false;

if((process.env.NODE_ENV) == 'production'){
  window.dlistHost = 'https://datelist.io';
  // eslint-disable-next-line no-unused-vars
  window.dlist = function(){
    let uuid = arguments[0];

    let mode, language, product_id, show_duration, default_values = null;
    if(arguments.length > 1){
      if(arguments[1] instanceof Object){
        // New syntax: dlist(uuid, options)
        let options = arguments[1];
        mode = options.mode;
        language = options.language;
        product_id = options.product_id;
        show_duration = options.show_duration;
        default_values = options.default_values;
      }
      else{
        // Old syntax dlist(uuid, mode, language, ...)
        [uuid, mode, language, product_id, show_duration, default_values] = arguments;
      }
    }

    new Vue({
      render: h => h(Widget, {props: {uuid: uuid, forcedMode: mode, forcedLanguage: language, forcedProductId: product_id, forcedDuration: show_duration, defaultValues: default_values}}),
    }).$mount('#dlist');
  }
}
else{
  window.dlistHost = 'http://localhost:3000';
  new Vue({
    render: h => h(App, {props: {uuid: 'ed47b90c-f6f1-475b-baf1-a4aa43b38329'}}),
  }).$mount('#app');
}
