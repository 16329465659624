<template>
  <div :class="'dlist-widget__products'">
    <div class="dlist__loader" v-if="loading">
      <Loader />
    </div>
    <div class="dlist-widget__header">
      <div class="dlist-widget__header__image">
        <img :src="imageUrl" v-if="imageUrl" />
      </div>
      <div class="dlist-widget__header__titles">
        <h1>{{ translations["calendar_title"] }}</h1>
        <h2>{{ translations["calendar_subtitle"] }}</h2>
      </div>
    </div>
    <div class="dlist-widget__products__emptystate" v-if="!loading && products.length == 0">
      {{ translations["products_emptystate"] }}
    </div>
    <div
      class="dlist__product"
      v-for="(product, index) in products"
      :key="index"
      @click.prevent="
        $emit('select', product);
      "
    >
      <span class="dlist__product__name">{{translations["product_" + product.id + "_name"] }}</span>
      <span class="dlist__product__price" v-if="requirePayment != 'no'">{{ product.total_price }} {{currencyCode}}</span>
      <span class="dlist__product__description" v-if="product.description" v-html="translations['product_' + product.id + '_description']">
      </span>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
export default {
  components: {Loader},
  props: ["loading", "imageUrl", "translations", "products", "requirePayment", "currencyCode"],
};
</script>
